import { Alert, Form, InputGroup } from "react-bootstrap";
import micIcon from "../../assets/images/mic-icon.svg";
import Button from "../FormElements/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import conversationService from "../../helper/Services/ConversationService";
import withWebSocket from "../../HOC/webSocketHoc";
import alertIcon from "../../assets/images/alert-bell.svg";
import Common from "../../Utils/Common";
import { FaStopCircle } from "react-icons/fa";
import { VscSend } from "react-icons/vsc";
import { addConversations, appHandleChatHasMore, appHandleNewConversation, appRefetchConversationHistory, handleGenerateChatId, handleSocketLoading, handleSocketMessage, handleSourceCount } from "../../redux/appSlice";


const PrimaryChatBox = ({ sendUrl, ws, setSocketResult }) => {
  const dispatch = useDispatch()
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const { pageNumber, selectedKnowledge, conversationId, refetchChat, messages, socketLoading } = useSelector((state) => {
    return {
      selectedKnowledge: state?.app?.post,
      messages: state?.app?.messages,
      pageNumber: state?.app?.chatPageNumber,
      conversationId: state?.app?.conversationId,
      refetchChat: state?.app?.refetchConversationHistory,
      socketLoading: state?.app?.socketLoading,
    } 
  })

  useEffect(() => {
    fetchChatHistory(pageNumber)
  }, [conversationId, pageNumber])

  useEffect(() => {
    if (refetchChat && !pageNumber) {
      fetchChatHistory()
    }
  }, [refetchChat])

  const handleChange = (e) => {
    const { value } = e.target;
    setText(value)
  }

  const handleSendMessage = async () => {
    if (!selectedKnowledge?.Id) return null
    const body = {
      message: text,
      kbid: selectedKnowledge?.Id
    }
    setLoading(true)
    let res = null
    if (conversationId) {
      res = await conversationService.createChatWithExistingConversation(conversationId, body)
    } else {
      res = await conversationService.createConversation(body);
      dispatch(appHandleNewConversation(res.conversationId))
    }

    if (res?.wss_url) {
      const list = [{
        iters: [{...res.usermsg}]
      }]
      setText('')
      setLoading(false)
      dispatch(handleSocketLoading(true))
      sendUrl(res?.wss_url)
      const _newList = [...list, ...messages]
      dispatch(addConversations(_newList))
    }
  }

  const handleRefetch = () => {
    setTimeout(() => {
      dispatch(handleSocketMessage(''))
      dispatch(appRefetchConversationHistory(true))
    }, 500)
  }

  const handleStopAiResponse = async () => {
    setLoading(true)
    const msg = messages[0].iters?.find((el) => el.agent === Common.USER_TYPE.HUMAN)
    const res = await conversationService.stopAiResponse(conversationId, msg?.id)
    if (res && ws) {
      ws.close();
      handleRefetch()
    }
    setLoading(false)
  }

  const fetchChatHistory = async (page = 0, wssUrl) => {
    if (!conversationId) return null;
    const request = {
      offset: page*Common.CHAT_PAGE_SIZE,
      limit: Common.CHAT_PAGE_SIZE
    }
    if(wssUrl){
      dispatch(handleSocketLoading(true))
    }
    const res = await conversationService.getChatHistory(conversationId, request)
    if (res) {
      let list = [...res];
      if (page) {
        list = [...messages, ...list]
      }
      let _hasMore = true
      if (!res?.length || res?.length < 10) {
        _hasMore = false
      }
      dispatch(appHandleChatHasMore(_hasMore))
      dispatch(addConversations(list))
      dispatch(appRefetchConversationHistory(false))
      dispatch(handleSocketLoading(false))
      dispatch(handleGenerateChatId(null))
      dispatch(handleSourceCount(null))
      dispatch(handleSocketMessage(''))
      setSocketResult('')
    }
  }

  const handleSend = (e) => {
    e.preventDefault();
    if (socketLoading | loading) {
      handleStopAiResponse()
    } else {
      handleSendMessage()
    }
  }

  return (
    <>
      <div className="chat-child mt-auto chat-child-last">
      {(text && !selectedKnowledge) && (
          <Alert variant="danger" className="chat-alert-bar">
            <img src={alertIcon} alt="alert" />
            Oops, no knowledge source added, Please select one from the search bar to continue.
          </Alert>
        )}
        <div className="chat-input-wrapper">
          <form>
            <InputGroup className="chat-input-bar">
              <InputGroup.Text id="basic-addon1" className="tooltip-container">
                <img src={micIcon} className="img-fluid" alt="Google Icon" />
                <span className="tooltip-text">Coming Soon</span>
              </InputGroup.Text>
              <Form.Control
                value={text}
                onChange={handleChange}
                placeholder="How can I help you"
              />
              <InputGroup.Text id="basic-addon1">
                <Button
                  variant="link"
                  className="pe-0 send-btn"
                  type='submit'
                  onClick={handleSend}
                  disabled={false}
                  text={(socketLoading || loading) ? <FaStopCircle color="#000" size='23' /> : <VscSend size='23' />}
                />
              </InputGroup.Text>
            </InputGroup>
          </form>
          <p className="chat-suggest-text">Cumin AI can make mistakes. Check important info.</p>
        </div>
      </div>
    </>
  );
};

export default withWebSocket(PrimaryChatBox);