import React from 'react';
import Button from '../FormElements/Button';
import './Pagination.Style.scss'

const Pagination = ({ page = 0, totalRecords = 0, handlePage }) => {
    return (
        <div className="pagination-bar search-pagination">
            <ul>
                <li>
                    <Button
                        variant="secondary"
                        className={`slide ${page === 0 ? "" : "active"}`}
                        text={"<"}
                        disabled={page === 0}
                        onClick={() => handlePage(page - 1)}
                    />
                </li>
                <li>
                    <Button
                        disabled={true}
                        variant="secondary"
                        className="counts"
                        text={`${page + 1} of ${totalRecords}`}
                    />
                </li>
                <li>
                    <Button
                        text=">"
                        variant="secondary"
                        className={`slide ${page === totalRecords - 1 ? "" : "active"}`}
                        onClick={() => handlePage(page + 1)}
                        disabled={page === totalRecords - 1}
                    />
                </li>
            </ul>
        </div>
    )
}
export default React.memo(Pagination);