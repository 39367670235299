import React, {forwardRef} from 'react';
import { Spinner, Button as BootstrapButton } from 'react-bootstrap';
import './FormElements.Style.scss'

const Button = forwardRef((props, ref)=>{
    const {text, loading, disabled} = props
    return(
        <BootstrapButton 
            ref={ref}
            {...props}
            disabled={disabled || loading}
        >
            {loading && <div className='spinner'><Spinner size="sm" className='me-2'/></div>}
            {text}
        </BootstrapButton>
    )
})
export default Button;