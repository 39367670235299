import React from 'react';
import { Navigate } from 'react-router';
const LandingPage = React.lazy(() => import("../Pages/LandingPage/LandingPage"));
const Logout = React.lazy(() => import("../Pages/Auth/Logout"));

const PublicRoutes = [
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <MissingRoute />,
    },
]

/**
 * @NAVIGATE_TO_LOGIN
 * Below method will redirect to login page if path is missing/not defined
 */
function MissingRoute() {
  return <Navigate to={{pathname: '/'}} />
 }

export default PublicRoutes;