import { useDispatch, useSelector } from "react-redux";
import "./LandingPage.Style.scss";
import Features from "./Components/Features";
import Message from "../../components/PrimaryChatBox/Message";
import { useEffect, useLayoutEffect } from "react";
import { appSelectPost, handleResetApp } from "../../redux/appSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import appService from "../../helper/Services/appService";

const LandingPage = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { messages } = useSelector((state) => {
    return {
      messages: state?.app?.messages
    };
  });

  useLayoutEffect(()=>{
    dispatch(handleResetApp(null));
  },[])

  useEffect(()=>{
    if(params?.id && location?.pathname===`/kb/${params?.id}`){
      fetchKnowledgeById()
    }
  },[])

  const fetchKnowledgeById = async() => {
		const res = await appService.searchKnowledgeByKbId(params?.id);
		if(res){
			dispatch(appSelectPost(res))
      navigate('/')
		}
	}

  return (
    <>
      {messages?.length ? <Message data={messages}/> : <Features/>}
    </>
  );
};

export default LandingPage;
