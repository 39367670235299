import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loader.Style.scss'

const PageLoader = ({spinner}) => {
    return(
        spinner ? 
        <div className='page-loading'>
            <Spinner
                animation="grow" 
                variant="primary"
            />
        </div>
         : null
    )
}
export default PageLoader;