import { useRoutes } from "react-router-dom";
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { useSelector } from "react-redux";
import Layout from "../components/Layout/Layout";

export function AppRoute() {
    const {isAuth} = useSelector((state)=>{
        return{
            isAuth: state.auth?.user
        }
    })
    let allRoutes = PublicRoutes;

    if(isAuth?.accessToken){
        allRoutes = PrivateRoutes;
    }

    let element = useRoutes(allRoutes)
    
    /**
     * @IS_AUTH
     * If user is logged in then private routes will be accessible
     */
    return <Layout>{element}</Layout>
}