import { Card, Col, Container, Row } from "react-bootstrap";
import logoMain from "../../../assets/images/cumin-logo-vertical.svg";
import chatIcon from "../../../assets/images/chat-icon.svg";
import capabilityIcon from "../../../assets/images/capability-icon.svg";
import limitationIcon from "../../../assets/images/limitations-icon.svg";

const Features = () => {
  return (
    <section className="feature-wrapper new-conversation">
      <Container fluid>
        <Row>
          <Col md={12} className="text-center">
            <img alt='' src={logoMain} className="img-fluid feature-logo" />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card className="feature-card">
              <div className="card-header">
                <img alt='' className="img-fluid icon" src={chatIcon} />
                <h4 className="card-title">Examples</h4>
              </div>
              <Card.Body>
                <ul className="feature-list">
                  <li>"What are recent updates from Google I/O?"</li>
                  <li>"What happened at the most recent Auto Expo?"</li>
                  <li>
                    "Summarize the minutes of the most recent AGM of Reliance."
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="feature-card">
              <div className="card-header">
                <img alt='' className="img-fluid icon" src={capabilityIcon} />
                <h4 className="card-title">Capabilities</h4>
              </div>
              <Card.Body>
                <ul className="feature-list">
                  <li>
                    Have free-flow conversations grounded upon Cumin AI
                    knowledge
                  </li>
                  <li>
                    Get latest updates directly on Cumin AI. No knowledge
                    cutoffs anymore
                  </li>
                  <li>
                    Use vast Cumin AI knowledge base for contextual
                    conversations.
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="feature-card">
              <div className="card-header">
                <img alt='' className="img-fluid icon" src={limitationIcon} />
                <h4 className="card-title">Limitations</h4>
              </div>
              <Card.Body>
                <ul className="feature-list">
                  <li>May occasionally generate incorrect information.</li>
                  <li>
                    May occasionally produce harmful instructions or biased
                    content.
                  </li>
                  <li>
                    Underlying language model in FREE tier may be prone to
                    hallucinations.
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;