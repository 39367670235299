import path from "../apiPath";
import {Api} from '../axios'

const conversationService = {
    getConversationHistory,
    deleteConversationHistory,
    createConversation,
    getChatHistory,
    linkKbToConversation,
    stopAiResponse,
    createChatWithExistingConversation,
    conversationFeedback,
}

async function getConversationHistory (params){
    const res = await Api.get(path.conversation.conversations, params);
    return res?.conversations || []
}

async function deleteConversationHistory (params){
    const res = await Api.delete(path.conversation.conversations, params);
    return res || null
}

async function createConversation (body){
    const res = await Api.post(path.conversation.createConversation, body);
    return res || null
}

async function getChatHistory ( conversationId, params){
    const res = await Api.get(`${path.conversation.conversations}/${conversationId}`, params);
    return res?.chats?.msgs || []
}

async function createChatWithExistingConversation ( conversationId, params){
    const res = await Api.post(`${path.conversation.conversations}/${conversationId}`, params);
    return res || null
}

async function linkKbToConversation ( conversationId, knowledgeId){
    const res = await Api.post(`${path.conversation.conversations}/${conversationId}/link/${knowledgeId}`);
    return res || null
}

async function stopAiResponse ( conversationId, messageId){
    const res = await Api.post(`${path.conversation.conversations}/${conversationId}/${messageId}`);
    if(res?.message==='SUCCESS'){
        return true
    }
    return null
}

async function conversationFeedback ( url, body){
    const res = await Api.post(`${path.feedback.feedback}/${url}`, body);
    if(res?.message==='SUCCESS'){
        return true
    }
    return null
}

export default conversationService