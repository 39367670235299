import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AppRoute } from "./AppRouter";
import { Suspense, useEffect, useState } from "react";
import PageLoader from "./components/Loading/PageLoader";
import { AxiosProvider } from "./helper/axios";
import Config from "./helper/Config";
import MobileComponent from "./components/System/MobileComponent";

function App() {
  const windowSize = window.innerWidth
  const [mobileScreen, setMobileScreen] = useState(windowSize<768 ? true : false)

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    return()=> window.removeEventListener('resize', handleResize);
  },[])

  const handleResize = () => {
    let isMobile = false
    if(window.innerWidth < 768){
      isMobile = true
    }
    setMobileScreen(isMobile)
  }

  return (
    <>
      {mobileScreen ? <MobileComponent/> :
      <GoogleOAuthProvider 
        clientId={Config.googleAppId}
      >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<PageLoader spinner={true}/>}>
                <BrowserRouter>
                  <AxiosProvider>
                    <AppRoute/>
                  </AxiosProvider>
                </BrowserRouter>
              </Suspense>
            </PersistGate>
        </Provider>
      </GoogleOAuthProvider>}
    </>
  );
}

export default App;
