import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  post: null,
  messages: [],
  chatPageNumber: 0,
  conversationId: null,
  refetchConversationHistory: null,
  newConversationId: null,
  chatHasMore: false,
  socketLoading: false,
  socketMessage: '',
  conversationTitle: '',
  sourceCount: null,
  chatId:null
};

const appSlice = createSlice({
  
  name: 'app',
  initialState,
  reducers: {
    appSelectPost: (state, action) => {
      state.post = action.payload;
    },
    addConversations: (state, action) => {
      state.messages = action.payload
    },
    appChatPageNumber: (state, action) => {
      state.chatPageNumber = action.payload
    },
    appHandleConversationId: (state, action) => {
      state.conversationId = action.payload
    },
    appRefetchConversationHistory: (state, action) => {
      state.refetchConversationHistory = action.payload
    },
    appHandleNewConversation: (state, action) => {
      state.newConversationId = action.payload
    },
    appHandleChatHasMore: (state, action) => {
      state.chatHasMore = action.payload
    },
    handleSocketLoading: (state, action) => {
      state.socketLoading = action.payload
    },
    handleSocketMessage: (state, action)=>{
      state.socketMessage = action.payload
    },
    handleConversationTitle: (state, action) => {
      state.conversationTitle = action.payload
    },
    handleSourceCount: (state, action) => {
      state.sourceCount = action.payload
    },
    handleGenerateChatId: (state, action) => {
      state.chatId = action.payload
    },
    handleResetApp: () => {
      return initialState
    }
  },
});

export const { handleResetApp, handleSourceCount, handleSocketMessage, handleSocketLoading, appSelectPost, addConversations, appChatPageNumber, appHandleConversationId, appRefetchConversationHistory, appHandleChatHasMore, appHandleNewConversation, handleConversationTitle,handleGenerateChatId } = appSlice.actions;
export default appSlice.reducer;
