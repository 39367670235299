
import { useParams } from 'react-router-dom';
import Common from '../../Utils/Common';
import { Card, Col, Row, Button } from 'react-bootstrap';
import TypeWriter from '../TypeWriter/TypeWriter';
import React, { useEffect, useMemo, useState } from "react";
import Pagination from '../Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import conversationService from '../../helper/Services/ConversationService';
import { handleGenerateChatId, handleSocketLoading } from '../../redux/appSlice';
import Markdown from 'react-markdown';
import cuminIcon from "../../assets/images/cumin-logo-icon.svg"
import reloadIcon from "../../assets/images/reload-icon.svg"
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoCopyOutline } from "react-icons/io5";
import { IoCheckmarkSharp } from "react-icons/io5";
import { HiOutlineThumbDown } from "react-icons/hi";
import { HiThumbDown } from "react-icons/hi";


const BoatMessageUI = (props) => {
    const { iters, chatHistory, isTypeWritter, sendUrl, reg } = props
    const dispatch = useDispatch()
    const [page, setPage] = useState(0)
    const [copied, setCopied] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [dislike, setDislike] = useState(false)
    const params = useParams()

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(null);
            }, 1000);
        }
    }, [copied]);

    const { sourceCount, selectedKnowledge, chatId } = useSelector((state) => {
        return {
            sourceCount: state?.app?.sourceCount,
            selectedKnowledge: state?.app?.post,
            chatId: state?.app?.chatId,

        }
    })

    const handleRegenerate = async (messageId) => {
        if (!selectedKnowledge?.Id) return null;
        const iter = chatHistory[1]?.iters[0]
        const body = {
            message: iter?.msg,
            kbid: selectedKnowledge?.Id,
            messageId: iter?.id
        };
        const res = await conversationService.createChatWithExistingConversation(params?.id, body);
        if (res?.wss_url) {
            dispatch(handleGenerateChatId(messageId))
            dispatch(handleSocketLoading(true));
            sendUrl(res?.wss_url);
        }
    }

    const handleFeedback = async (messageId) => {
        const body = {
            dislike: true
        };
        const res = await conversationService.conversationFeedback(`${params?.id}/${messageId}`, body)
        if (res) {
            setDislike(true)
        }
    }

    const oldMessage = useMemo(() => {
        if (typeof (iters) === 'object') {
            setPage(() => iters?.length - 1)
            setTotalRecords(iters?.length)
        }
        return iters
    }, [iters])

    let message = oldMessage;
    if (typeof (iters) === 'object') {
        message = iters[page]
    }

    return (
        <Row className={`justify-content-start chat-message-row ${message?.id === chatId ? 'd-none' : ''}`}>
            <Col lg={9}>
                <Card className="message-box user-message-box">
                    <div>
                        <img src={cuminIcon} alt="logo" />
                    </div>
                    <div>
                        <div className="message-head">
                            <h4 className="user-name">Cumin AI</h4>
                            <h4 className="time">
                                {Common.cuminAiDateFormat(isTypeWritter ? new Date() : message?.createdOn, 'hh:mm A')}
                            </h4>
                            {isTypeWritter && (
                                <h4 className='time'>{sourceCount ? `Analyzed ${sourceCount} sources` : 'Analyzing...'}</h4>
                            )}
                        </div>
                        <div className="message-body">
                            <p className="message-text">
                                {isTypeWritter ? <TypeWriter text={message?.msg || message} /> : <Markdown>{message?.msg}</Markdown>}
                            </p>
                            {/* <div className="chat-social-btns">
                                <Button variant="plain">
                                    <img src={googleLogo} className="icon" alt="google" />
                                    google.com
                                    <img src={redirectIcon} alt="google" />
                                </Button>
                                <Button variant="plain">
                                    <img src={googleLogo} className="icon" alt="google" />
                                    medium.com
                                    <img src={redirectIcon} alt="google" />
                                </Button>
                                <Button variant="plain">
                                    <img src={googleLogo} className="icon" alt="google" />
                                    producthunt.com
                                    <img src={redirectIcon} alt="google" />
                                </Button>
                                <Button variant="plain">
                                    <img src={googleLogo} className="icon" alt="google" />
                                    linkedin.com
                                    <img src={redirectIcon} alt="google" />
                                </Button>
                            </div> */}

                            {!isTypeWritter && (
                                <div className="message-action-bar">
                                    <div className="left-items">
                                        {reg == 0 &&
                                            <Button variant="plain" className='tooltip-box' onClick={() => handleRegenerate(message?.id)}>
                                                <img src={reloadIcon} className="img-fluid" alt="reload" />
                                                <span class="tooltiptext">Regenerate</span>
                                            </Button>
                                        }
                                        <div className="align-items-center d-flex justify-content-center tooltip-box">
                                            {copied ? <IoCheckmarkSharp size='16' color='#666f8d' /> :
                                                <CopyToClipboard
                                                    text={message?.msg}
                                                    onCopy={() => setCopied(true)}
                                                >
                                                    <IoCopyOutline size='16' color='#666f8d' />
                                                </CopyToClipboard>

                                            }
                                            <span class="tooltiptext">Copy</span>
                                        </div>
                                        <Button variant="plain" className='tooltip-box' onClick={() => !dislike && handleFeedback(message?.id)}>
                                            {dislike ? <HiThumbDown size='16' color='#666f8d' /> : <HiOutlineThumbDown size='16' color='#666f8d' />}
                                            <span class="tooltiptext">Bad response</span>
                                        </Button>
                                    </div>
                                    {iters?.length > 1 ? <Pagination page={page} totalRecords={totalRecords} handlePage={setPage} /> : null}
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}
export default BoatMessageUI;