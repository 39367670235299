import path from "../apiPath";
import {Api} from '../axios'

const authService = {
    authGoogleLogin,
    authRefreshToken,
    getAuthToken,
    updateAuthToken
}

async function authGoogleLogin (body){
    const res = await Api.post(path.auth.googleLogin, body);
    await setAuthToken(res?.data || res)
    return res
}

async function authRefreshToken (body){
    const res = await Api.get(path.auth.refreshToken, body);
    await updateAuthToken(res?.data || res)
    return res?.data || res
}

async function setAuthToken(data){
    let cuminAiToken = localStorage.getItem('cuminAiToken')
    cuminAiToken = {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken
    }
    localStorage.setItem('cuminAiToken', JSON.stringify(cuminAiToken))
}

async function updateAuthToken(data){
    let cuminAiToken = localStorage.getItem('cuminAiToken');
    if(cuminAiToken){
        const tokenParse = JSON.parse(cuminAiToken);
        tokenParse.accessToken = data?.accessToken
        localStorage.setItem('cuminAiToken', JSON.stringify(tokenParse))
    }
}

async function getAuthToken(){
    const cuminAiToken = localStorage.getItem('cuminAiToken')
    if(cuminAiToken){
        const tokenParse = JSON.parse(cuminAiToken)
        return tokenParse
    }
    return null
}

export default authService