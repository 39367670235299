import moment from 'moment';

const dateFormat = 'MMM D, YYYY'
const PAGE_SIZE = 10;
const CHAT_PAGE_SIZE = 20;

const USER_TYPE = {
    HUMAN: 'HUMAN',
    BOT: 'BOT'
}

const SOCKET_STATUS = {
    START_RESPONSE: "START_RESPONSE",
    RESPONSE_SUCCESS: "RESPONSE_SUCCESS",
    START_TITLE_RESPONSE: "START_TITLE_RESPONSE"
}

function cuminAiDateFormat(date, format=dateFormat){
    return moment(date).format(format);
}

const Common = {
    PAGE_SIZE,
    USER_TYPE,
    CHAT_PAGE_SIZE,
    SOCKET_STATUS,
    cuminAiDateFormat,
}
export default Common;