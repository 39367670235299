import React, { useEffect } from 'react';
import Message from '../../components/PrimaryChatBox/Message';
import { useParams } from 'react-router-dom';
import { appHandleConversationId } from '../../redux/appSlice';
import { useDispatch, useSelector } from 'react-redux';

const ChatConversation = () => {
    const dispatch = useDispatch();
    const {id} = useParams()

    const { messages } = useSelector((state) => {
        return {
          messages: state?.app?.messages
        };
    });

    useEffect(()=>{
        dispatch(appHandleConversationId(id))
    },[id])

    return(
        <>
            <Message data={messages}/>
        </>
    )
}
export default React.memo(ChatConversation);