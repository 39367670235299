import { Link, useNavigate } from "react-router-dom";
import trashIcon from "../../assets/images/trash-icon.svg";
import communityIcon from "../../assets/images/community-icon.svg";
import logoutIcon from "../../assets/images/logout-icon.svg";
import addIconWhite from "../../assets/images/add-white-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../../redux/authSlice";
import Config from "../../helper/Config";
import ConversationHistory from "./Components/ConversationHistory";
import { useState } from "react";
import Button from "../FormElements/Button";
import conversationService from "../../helper/Services/ConversationService";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [hasDeleted, setHasDeleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.auth.user);

  const handleLogount = () => {
    dispatch(authLogout());
    navigate('/logout')
  };

  const deleteConversation = async() => {
    setLoading(true)
    const res = await conversationService.deleteConversationHistory()
    if(res?.message==='SUCCESS'){
      setHasDeleted(true)
      setLoading(false)
      navigate('/')
    }
  }

  return (
    <div className="sidebar-box">
      <div className="side-item">
        <Link 
          to='/'
          className="btn-add btn btn-primary"
        >
          <img alt='' src={addIconWhite} className="img-fluid icon" />
          New Chat
        </Link>
        {user && <ConversationHistory refetch={hasDeleted} setHasDeleted={setHasDeleted}/>}
      </div>
      <div>
        <ul className="side-list list-bottom">
          <li>
            <Button
                variant="light" 
                className="link-item"
                loading={loading}
                text={<><img alt='' src={trashIcon} className="img-fluid" />Clear conversations</>}
                onClick={deleteConversation}
            />
          </li>
          <li>
            <a 
              href={Config.supportUrl} 
              className="link-item"
              target="_blank"
              rel="noreferrer"
            >
              <img alt='' src={communityIcon} className="img-fluid" />
              Community Support
            </a>
          </li>
          {user && (
            <li>
              <Button 
                className="link-item log-out-btn" 
                onClick={handleLogount}
                disbaled={loading}
                text={<><img alt='' src={logoutIcon} className="img-fluid" />Log out</>}
              />
            </li>
          )}
        </ul>
        {user && (
          <div className="d-flex align-items-center gap-2 user-detail">
            <img alt='' src={user?.client?.picUrl} className="user-profile" />
            <div>
              <p className="user-name mb-0">{user?.client?.givenName} {user?.client?.familyName}</p>
              <p className="user-email mb-0">{user?.client?.email}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
