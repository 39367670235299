import Sidebar from "./Sidebar";
import "./Layout.scss";
import TopSearchBar from "../TopSearchBar/TopSearchBar";
import { PostCard } from "../../components/TopSearchBar/KnowledgeCard";
import { useSelector } from "react-redux";
import PrimaryChatBox from "../PrimaryChatBox/PrimaryChatBox";
import Login from "../../Pages/Auth/Login";

const Layout = ({ children }) => {
  const pathName = window.location.pathname
  const { user, selectedPost } = useSelector((state) => {
      return {
        user: state?.auth?.user,
        selectedPost: state?.app?.post,
      };
    });

    if(pathName==='/logout'){
      return children
    }

  return (
    <>
      {(!user && pathName!=='/logout') && (
        <Login
          show={true}
        />
      )}
      <div className="layout-wrapper">
        <div className="layout-box">
          <Sidebar />
          <div className="main-wrapper">
            <div className="chat-wrapper">
              <div className="search-bar-wrapper">
                <TopSearchBar />
              </div>
              {selectedPost && (
                <div className="chat-child post-wrapper-child">
                  <PostCard post={selectedPost} Collapse={false}/>
                </div>
              )}
              <div className={`body-wrapper ${selectedPost ? 'post' : ''}`}>
                {children}
              </div>
              <PrimaryChatBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
