import React from 'react';
import LandingPage from "../Pages/LandingPage/LandingPage";
import ChatConversation from "../Pages/ChatConversation/ChatConversation";

const PrivateRoutes = [
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/kb/:id",
      element: <LandingPage />,
    },
    {
      path: "/conversation/:id",
      element: <ChatConversation />,
    },
]

export default PrivateRoutes;
