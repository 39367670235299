import React, {useState, useEffect} from 'react';
import cuminAiLogo from '../../assets/images/cumin-logo-vertical.svg';
import viewPortGraphic from "../../assets/images/viewport-graphic.svg";
import { Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import './System.Style.scss';

const MobileComponent = () => {

	const [copied, setCopied] = useState(false);

	useEffect(() => {
		if (copied) {
		  setTimeout(() => {
			setCopied(null);
		  }, 3000);
		}
	}, [copied]);

	return (
		<>
		<section className='mobile-screen'>
		    <div className={`copied-message btn btn-primary ${copied ? 'visible' : ''}`}>
                COPIED TO CLIPBOARD
            </div>
			<div className='content'>
				<img src={cuminAiLogo} alt="logo" />
				<img src={viewPortGraphic} alt='graphic' />
				<div className='btn-box'>
					<Button>Playground is available on Laptop, PC and Tablets</Button>
					<CopyToClipboard
						text={`${window.location.origin}`}
						onCopy={() => setCopied(true)}
					>
						<Button variant='link'>Share Link</Button>
					</CopyToClipboard>
				</div>
			</div>
		</section>
		</>
	)
}
export default MobileComponent;