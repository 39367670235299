

const path = {
    auth: {
        googleLogin: 'sociallogin/google',
        refreshToken: 'session'
    },
    app: {
        searchByKnowledge: 'search/knowledge/bycreator',
        searchKnowledge: 'search/knowledge'
    },
    conversation: {
        conversations: 'conversations',
        createConversation: 'conversations/new',
    },
    feedback: {
        feedback: 'feedback/cuminailm',
    }
}
export default path;