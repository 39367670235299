import { Card, Col, Container, Row } from "react-bootstrap";
import Common from "../../Utils/Common";
import React, { useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { appChatPageNumber } from '../../redux/appSlice'
import withWebSocket from '../../HOC/webSocketHoc'
import BoatMessageUI from "./BoatMessageUI";

const Message = ({ sendUrl }) => {
	const dispatch = useDispatch()
	const { messages, hasMore, pageNumber, socketLoading, socketMessage } = useSelector((state) => {
		return {
			messages: state?.app?.messages,
			hasMore: state?.app?.chatHasMore,
			pageNumber: state?.app?.chatPageNumber,
			socketLoading: state?.app?.socketLoading,
			socketMessage: state?.app?.socketMessage,
		}
	})

	const chatContainerRef = useRef(null);
	const chatHistory = useMemo(() => {
		let list = [...messages]
		return list
	}, [messages])

	const _renderSenderUi = (message, index) => {
		return (
			<Row key={index} className="justify-content-end chat-message-row">
				<Col lg={6}>
					<Card className="message-box">
						<div className="message-head">
							<h4 className="user-name">You</h4>
							<h4 className="time">{Common.cuminAiDateFormat(message?.createdOn, 'hh:mm A')}</h4>
						</div>
						<div className="message-body">
							<p className="message-text">{message.msg}</p>
						</div>
					</Card>
				</Col>
			</Row>
		)
	}

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [socketLoading]);

	const handleFetchMore = () => {
		dispatch(appChatPageNumber(pageNumber + 1))
	}

	return (
		<div className="chat-child chat-child-second">
			<section className="feature-wrapper">
				<Container 
					fluid 
					className="d-flex" 
					id="scrollableDiv" 
					ref={chatContainerRef}
					style={{display: 'flex', flexDirection: 'column-reverse', overflow: 'auto'}}
				>
					{(socketLoading || socketMessage) && <BoatMessageUI iters={socketMessage} isTypeWritter={true} />}
					<InfiniteScroll
						dataLength={chatHistory?.length} //This is important field to render the next data
						next={handleFetchMore}
						hasMore={hasMore}
						loader={<p className='text-center'><small>Loading...</small></p>}
						endMessage={
							chatHistory?.length > Common.CHAT_PAGE_SIZE ?
								<p className='text-center mb-4'>
									<b>Yay! You have seen it all</b>
								</p> : ''
						}
						scrollableTarget="scrollableDiv"
						inverse={true}
					>
						{chatHistory?.map((list, reg) => {
							const isHuman = list?.iters?.find((el) => el?.agent === Common.USER_TYPE.HUMAN);
							if (isHuman) {
								return _renderSenderUi(list?.iters[0])
							} else if (!isHuman) {
								return <BoatMessageUI reg={reg} iters={list.iters} chatHistory={chatHistory} sendUrl={sendUrl} />
							}
							return null
						})}
					</InfiniteScroll>
				</Container>
			</section>
		</div>
	)
}

export default withWebSocket(Message);