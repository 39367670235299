import axios from "axios";
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import authService from "./Services/authService";
import path from "./apiPath";
import { authSetAccessToken, authLogout } from "../redux/authSlice";
import Config from "./Config";
import { useNavigate } from "react-router-dom";
import PageLoader from "../components/Loading/PageLoader";

const instance = axios.create({
  baseURL: Config.baseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

const AxiosProvider = (props) => {
  const { children } = props;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const {accessToken} = useSelector((state)=>{
    return{
      accessToken: state?.auth?.user?.accessToken,
    }
  })

  useEffect(() => {
    axiosInitilization()
  },[]);

  const axiosInitilization = async() => {
    await createInstance();
    if(accessToken){
      setIsLoading(true)
      const res = await authService.authRefreshToken();
      if(res?.accessToken){
        dispatch(authSetAccessToken(res.accessToken))
      }
    }
    setIsLoading(false)
  }

  const createInstance = () => {
    instance.interceptors.request.use(async(req)=>{
      const token = await authService.getAuthToken()
      if(token?.accessToken){
        if(req.url===path.auth.refreshToken){
          req.headers.Authorization = `Bearer ${token.refreshToken}`
        }else{
          req.headers.Authorization = `Bearer ${token.accessToken}`
        }
      }
      return req
    })

    instance.interceptors.response.use((res)=>{
      return res?.data?.data || res?.data || res
    }, async(error)=>{
      const hasLogout = error?.response?.status===401
      if(error?.config?.url===path.auth.refreshToken && hasLogout){
        dispatch(authLogout())
        navigate({pathname: '/logout'}, {state: hasLogout})
      }else if(hasLogout && error.config.url!==path.auth.refreshToken && !error?.config?._retry){
        error.config._retry = true;
        const refreshToken = await authService.authRefreshToken()
        if(refreshToken?.accessToken){
          dispatch(authSetAccessToken(refreshToken.accessToken))
          return instance(error.config)
        }
      }
      return error?.response
    })
  };

  if(isLoading){
    return <PageLoader spinner={true}/>
  }

  return <div>{children}</div>;
};

class Api {
  /**
   * @url This is for api url
   * @params This is for api params
   */
  static async get(url, params) {
    let queryParams = "?";
    const newParams = params && Object.entries(params);
    for (let i in newParams) {
      if (typeof newParams[i][1] === "object" && newParams[i][1]?.length > 0) {
        for (let j in newParams[i][1]) {
          queryParams += `${newParams[i][0]}[${j}]=${newParams[i][1][j]}&`;
        }
      } else {
        queryParams += `${newParams[i][0]}=${newParams[i][1]}&`;
      }
    }
    if (queryParams && params) {
      url += queryParams;
      if (url.charAt(url.length - 1) === "&") {
        url = url.slice(0, -1);
      }
    }
    return await instance.get(url);
  }

  /**
   * @url This is for api url
   * @body This is for api body
   */
  static async post(url, body) {
    return await instance.post(url, body);
  }

  static async put(url, body) {
    return await instance.put(url, body);
  }

  /**
   * @url This is for api url
   */
  static async delete(url) {
    return await instance.delete(url);
  }
}

export { Api, AxiosProvider };
