import { Button, Card } from "react-bootstrap";
import plusIcon from "../../assets/images/plus-icon.svg";
import minusIcon from "../../assets/images/minus-icon.svg";
import heartIcon from "../../assets/images/heart-icon.svg";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import avatarIcon from "../../assets/images/avatar-rounded.svg";
import shareIcon from "../../assets/images/share-icon.svg";
import React, { useEffect, useState } from "react";
import Common from "../../Utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { appSelectPost } from "../../redux/appSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import conversationService from "../../helper/Services/ConversationService";

const KnowledgeCard = ({ data, setShow }) => {
  return (
    <>
      {data?.map((post, index) => {
        return <React.Fragment key={index}><PostCard post={post} setShow={setShow}/></React.Fragment>;
      })}

      {!data?.length ? (
        <section className="post-card-wrapper">
          <Card className="post-card">
            <Card.Body className="mt-0">
              <p className="no-record-found mb-0">
                Uh-oh! Our search ninjas couldn't find anything matching your
                query. Maybe they're on a coffee break.
              </p>
            </Card.Body>
          </Card>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export function PostCard({ post, setShow, Collapse=true }) {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(null);
  const [isCollapse, setIsCollapse] = useState(true);

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  const { kbId, conversationId, newConversationId } = useSelector((state) => {
    return {
      kbId: state?.app?.post?.Id,
      conversationId: state?.app?.conversationId,
      newConversationId: state?.app?.newConversationId
    };
  });

  useEffect(()=>{
    if(newConversationId && kbId){
      handleLinkConversation(kbId, newConversationId)
    }
  },[newConversationId, kbId])

  const handleLinkConversation = async(knowledgeId=null, id=conversationId) => {
    if(knowledgeId && id){
      await conversationService.linkKbToConversation(id, knowledgeId)
    }
  }

  useEffect(()=>{
    if(!Collapse){
      setIsCollapse(false)
    }
  }, [Collapse])

  const handleSelectPost = (post) => {
    dispatch(appSelectPost(post));
    handleLinkConversation(post?.Id)
    if(setShow){
      setShow(false)
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(null);
      }, 3000);
    }
  }, [copied]);

  return (
    <section className="post-card-wrapper">
      <Card className="post-card" onClick={() => handleSelectPost(post)}>
        <Card.Header>
          <div className="head-items">
            <Card.Title>{post?.Name}</Card.Title>
            <h5 className="title-type">version: {post?.Version}</h5>
          </div>
          <div className="head-items">
            <h4 className="status">{post?.Tier}</h4>
            <Button
              className="btn-toggle"
              variant="plain"
              onClick={handleCollapse}
            >
              <img
                alt=''
                className={`img-fluid plus ${
                  isCollapse === false ? "active" : ""
                }`}
                src={plusIcon}
              />
              <img
                alt=''
                className={`img-fluid minus ${
                  isCollapse === true ? "active" : ""
                }`}
                src={minusIcon}
              />
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <p
            className={`card-text ${
              isCollapse === true ? "d-block" : "d-none"
            }`}
          >
            {post?.Description}
          </p>
        </Card.Body>
        <Card.Footer>
          <div className="card-socials">
            <div className="btn-social">
              <img alt='' className="img-fluid icon" src={heartIcon} />
              {post?.Likes || 0}
            </div>
            <div className="btn-social">
              <img alt='' className="img-fluid icon" src={calendarIcon} />
              {Common.cuminAiDateFormat(post?.ReleasedDate)}
            </div>
            <div className="btn-social">
              <img alt='' className="img-fluid icon" src={avatarIcon} />
              {post?.Creator}
            </div>
          </div>
          <div>
            <div className="align-items-center d-flex justify-content-center">
              {copied && <div className="copied me-3 text-primary">Copied to clipboard!</div>}
              <CopyToClipboard
                text={`${window.location.origin}/kb/${post.Id}`}
                onCopy={() => setCopied(true)}
              >
                <img alt='' className="img-fluid" src={shareIcon} />
              </CopyToClipboard>
            </div>
          </div>
        </Card.Footer>
      </Card>
    </section>
  );
}

export default React.memo(KnowledgeCard);