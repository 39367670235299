import { GoogleLogin } from "@react-oauth/google";
import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/cumin-logo.svg";
import authService from "../../helper/Services/authService";
import { useDispatch } from "react-redux";
import { authLogin } from "../../redux/authSlice";
import Config from "../../helper/Config";
import googleIcon from "../../assets/images/google-icon.svg"
import { Link } from "react-router-dom";

const Login = ({ show, handleClose }) => {
  const dispatch = useDispatch();

  const handleLoginSuccess = async (response) => {
    const payload = { idToken: response.credential };
    const res = await authService.authGoogleLogin(payload);
    dispatch(authLogin(res));
  };

  const handleLoginFailure = (error) => {
    console.log("Login Failed:", error);
  };

  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      centered 
      className="auth-modal-ui"
    >
      <Modal.Body>
        <img src={logo} className="img-fluid mb-4" alt="Cumin AI Logo" />
        <div className="modal-headings">
          <h4 className="modal-subtitle">
            Welcome to <span className="green">Cumin AI Playground</span>
          </h4>
          <h4 className="modal-subtitle-light">
            Sign in with Google Account to continue
          </h4>
        </div>
        <div className="google-login-bar">
        <div className="position-relative w-100">
          <div className="position-absolute d-flex justify-content-center align-items-center left-0 w-100 google-login-btn">
          <img src={googleIcon} className="google-icon" alt="Google Icon" />
            Continue with Google
          </div>
          <div className="position-absolute left-0 w-100" style={{opacity: 0}}>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            useOneTap={false}
            text="continue_with"
            className="btn-google"
          />
          </div>
        </div>
        </div>
        <p className="small-text">
          By proceeding further you automatically consent to our <br />
          <Link to={Config.cuminAiTermsConditionUrl} target="_blank">Terms & Conditions</Link> and
          <Link to={Config.cuminAiPrivacyPolicyUrl} target="_blank">Privacy Policy</Link>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default Login;
