import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authLogin: (state, action) => {
      state.user = action.payload;
    },
    authLogout: (state) => {
      state.user = null;
      localStorage.setItem('cuminAiToken', null)
      localStorage.clear()
      // window.location.reload()
    },
    authSetAccessToken: (state, action) =>{
      state.user.accessToken = action.payload
    }
  },
});

export const { authLogin, authLogout, authSetAccessToken } = authSlice.actions;
export default authSlice.reducer;
