const Config = {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    googleAppId: process.env.REACT_APP_GOOGLE_APP_ID,
    cuminAiTermsConditionUrl: process.env.REACT_APP_CUMINAI_TERMS_CONDITIONS_URL,
    cuminAiPrivacyPolicyUrl: process.env.REACT_APP_CUMINAI_PRIVACY_POLICY_URL,
    supportUrl: process.env.REACT_APP_SUPPORT_URL,
    getStartedUrl: process.env.REACT_APP_GET_STATED_URL,
    cuminAiPlaygroundUrl: process.env.REACT_APP_CUMIN_AI_PLAYGROUND_URL
}

export default Config;