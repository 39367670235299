import path from "../apiPath";
import {Api} from '../axios'

const appService = {
    searchByKnowledge,
    searchKnowledgeByKbId
}

async function searchByKnowledge (params){
    const res = await Api.get(path.app.searchByKnowledge, params);
    return res.candidates || res?.data?.candidates || []
}

async function searchKnowledgeByKbId (kbId){
    const res = await Api.get(`${path.app.searchKnowledge}/${kbId}`);
    return res?.candidates[0] || null;
}

export default appService