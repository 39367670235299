import React, { useMemo } from 'react';
import Markdown from 'react-markdown'
import './TypeWriter.Style.scss'
import { useSelector } from 'react-redux';

const TypeWriter = ({ text }) => {
  // const [displayedText, setDisplayedText] = useState('');
  // const [index, setIndex] = useState(0);

  // useEffect(() => {
  //   if (index < text.length) {
  //     const interval = setInterval(() => {
  //       setDisplayedText((prev) => prev + text[index]);
  //       setIndex(index + 1);
  //     }, speed);

  //     return () => clearInterval(interval);
  //   }
  //   if(text?.length && index===text?.length){
  //     setTimeout(()=>{
  //       handleRefetch()
  //     },200)
  //   }
  // }, [index, text, speed]);

  const { socketLoading } = useSelector((state) => {
    return {
      socketLoading: state?.app?.socketLoading,
    }
  })

  const message = useMemo(()=>{
    return text
  },[text])

  console.log(55, message)

  return (
    <div className="typing-cursor">
      <Markdown>{message}</Markdown>
      {socketLoading ? <div class="typing-indicator"><span className="cursor-loading"></span> 
      </div> : ''}
    </div>
  );
};

export default React.memo(TypeWriter);