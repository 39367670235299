import { useEffect, useRef, useState } from "react";
import Common from "../../../Utils/Common";
import chatTextIcon from "../../../assets/images/chat-text-icon.svg";
import Button from "../../FormElements/Button";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from 'react-router-dom';
import { appHandleNewConversation, appSelectPost, handleResetApp  } from "../../../redux/appSlice";
import conversationService from "../../../helper/Services/ConversationService";
import appService from "../../../helper/Services/appService";

const ConversationHistory = ({ refetch, setHasDeleted }) => {
	const conversationRef = useRef(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [pageNumber, setPageNumber] = useState(0);
	const [loading, setLoading] = useState(false)
	const [hasMore, setHasMore] = useState(false)
	const [conversationData, setConversationData] = useState([])

	const {newConversationId, refetchChat, conversationId} = useSelector((state)=>{
		return{
			newConversationId: state?.app?.newConversationId,
			refetchChat: state?.app?.refetchConversationHistory,
			conversationId: state?.app?.conversationId
		}
	})

	useEffect(()=>{
		if(newConversationId){
			setConversationData([{Id: newConversationId, Name: 'New Chat'}, ...conversationData])
		}
	},[newConversationId])

	useEffect(() => {
		if(refetchChat && newConversationId){
			fetchConversationHistory(0)
		}
	}, [newConversationId, refetchChat])

	useEffect(() => {
		fetchConversationHistory()
	}, [pageNumber])

	useEffect(() => { 
		if (refetch) {
			fetchConversationHistory()
		}
	}, [refetch])

	useEffect(()=>{
		if(conversationId){
			const kbId =  conversationData?.find((el)=>el.Id===conversationId)?.KbId;
			fetchKnowledgeById(kbId)
		}
	},[conversationId, conversationData])

	const fetchKnowledgeById = async(kbId=null) => {
		if(!kbId) return
		const res = await appService.searchKnowledgeByKbId(kbId);
		if(res){
			dispatch(appSelectPost(res))
		}
	}

	const fetchConversationHistory = async (page=pageNumber) => {
		const payload = {
			offset: page*Common.PAGE_SIZE,
			limit: Common.PAGE_SIZE
		}
		setLoading(true)
		const res = await conversationService.getConversationHistory(payload);
		let list = [];
		if (res) {
			if (!refetch) {
				list = [...conversationData, ...res]
			}
			if(page===0){
				list = [...res]
			}
		}
		let _hasMore = true
		if(!res?.length || res?.length<10){
			_hasMore = false
		}
		setHasMore(_hasMore)
		setConversationData(list)
		setHasDeleted(false)
		setLoading(false)
		if(newConversationId){
			navigate('/conversation/'+newConversationId)
		}
		if(page){
			scrollToBottom()
		}
		dispatch(appHandleNewConversation(null))
	}

	const handleNavClick = () => {
		dispatch(handleResetApp(null))
	}

	const scrollToBottom = () => {
		if(conversationRef?.current){
			conversationRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<>
			<ul className="side-list side-data-list">
				{conversationData?.map((conversation, index) => {
					return (
						<li key={index}>
							<NavLink
								onClick={handleNavClick}
								to={`/conversation/${conversation?.Id}`}
								className={({ isActive }) =>`link-item ${isActive ? 'active' : ''}`}
								title={conversation?.Name ? conversation?.Name : 'New Chat'}
								>
								<img alt='' src={chatTextIcon} className="img-fluid" />
								{conversation?.Name ? conversation?.Name : 'New Chat'}
							</NavLink>
						</li>
					)
				})}
				<div ref={conversationRef}/>
			</ul>
			{hasMore && (
				<Button
					variant="light"
					className="btn-more"
					loading={loading}
					text="More"
					onClick={() => setPageNumber(pageNumber + 1)}
				/>
			)}
		</>
	)
}
export default ConversationHistory;