import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import searchIcon from "../../assets/images/search-icon.svg";
import appService from "../../helper/Services/appService";
import KnowledgeCard from "./KnowledgeCard";
import { useDispatch, useSelector } from "react-redux";
import { appSelectPost } from "../../redux/appSlice";
import Button from "../FormElements/Button";

const TopSearchBar = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(undefined);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const modalOverlayRef = useRef(null);

  const { user } = useSelector((state) => {
    return {
      user: state?.auth?.user,
    };
  });

  useEffect(() => {
    if (!user) {
      setShow(false);
      document.body.classList.remove("search-wrapper-open");
      setData([]);
    }
  }, [user]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalOverlayRef.current && event.target === modalOverlayRef.current) {
        setShow(false);
        document.body.classList.remove("search-wrapper-open");
      }
    }
    if (show) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  const handleSearch = async (e) => {
    if (loading) return null;
    e.preventDefault();
    setLoading(true);
    setShow(false);
    const body = { creator: text };
    const result = await appService.searchByKnowledge(body);
    setLoading(false);
    setShow(true);
    document.body.classList.add("search-wrapper-open");
    setData(result);
    setText('')
    dispatch(appSelectPost(""));
  };

  return (
    <>
      <form >
        <InputGroup className="search-bar">
          <InputGroup.Text id="basic-addon1">
            <img src={searchIcon} className="img-fluid" alt="" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search for knowledge source"
            name="search"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <InputGroup.Text id="basic-addon1">
            <Button
              variant="secondary"
              type="submit"
              onClick={handleSearch}
              loading={loading}
              text='Enter / to activate search'
            />
          </InputGroup.Text>
        </InputGroup>
      </form>
      <div className={`search-modal-wrapper ${show && "open"}`}>
        <div className="search-modal-card ">
          <div className="search-list">
            <KnowledgeCard data={data} setShow={setShow}/>
          </div>
          {/* <Pagination/>  */}
        </div>
      </div>
      {show && (
        <div
          ref={modalOverlayRef}
          className="search-modal-overlay"
          onClick={() => {
            setShow(false);
            document.body.classList.remove("search-wrapper-open");
          }}
        />
      )}
    </>
  );
};

export default TopSearchBar;