import React, { useEffect, useState } from 'react';
import Common from '../Utils/Common';
import { useDispatch } from 'react-redux';
import {  handleConversationTitle, handleSocketMessage, handleSourceCount, appRefetchConversationHistory } from '../redux/appSlice';

const withWebSocket = (WrappedComponent) => {
  return () => {
    const dispatch = useDispatch()
    const [ws, setWs] = useState(null);
    const [url, setUrl] = useState(null);
    const [socketResult, setSocketResult] = useState('');
    const [status, setStatus] = useState(null)

    useEffect(()=>{
      dispatch(handleSocketMessage(socketResult))
    },[socketResult])

    const handleRefetch = () => {
      setTimeout(()=>{
        dispatch(appRefetchConversationHistory(true))
      }, 1000)
    }

    useEffect(() => {
        if(url){
          const socket = new WebSocket(url);

          socket.onopen = () => {
            console.log('WebSocket Connected');
          };
          let status = null;
          let title = ''
          socket.onmessage = (event) => {
            if(event?.data?.includes(Common.SOCKET_STATUS?.START_RESPONSE)){
              status = Common.SOCKET_STATUS?.START_RESPONSE
              const source = event?.data.split(':')[2];
              dispatch(handleSourceCount(source))
            }
            if(event?.data?.includes(Common.SOCKET_STATUS?.RESPONSE_SUCCESS)){
              setStatus(Common.SOCKET_STATUS?.RESPONSE_SUCCESS)
              handleRefetch()
              dispatch(handleConversationTitle(title))
              status = null 
              title = null
            }
  
            if(status===Common.SOCKET_STATUS?.START_RESPONSE && !(event?.data?.includes(Common.SOCKET_STATUS?.START_RESPONSE) || event?.data?.includes(Common.SOCKET_STATUS?.RESPONSE_SUCCESS) ||  event?.data?.includes(Common.SOCKET_STATUS?.START_TITLE_RESPONSE))){
                setSocketResult((prev)=>prev+event.data)
            }
          };

          socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
          };

          socket.onclose = () => {
            console.log('WebSocket Closed');
          };

          setWs(socket);

          return () => {
            socket.close();
          };
        }
    }, [url]);

    const sendMessage = (message) => {
      if (ws) {
        ws.send(message);
      } else {
        console.error('WebSocket connection not established');
      }
    };

    return(
      <WrappedComponent 
        socketMessage={socketResult}
        setSocketResult={setSocketResult}
        status={status}
        setStatus={setStatus}
        ws={ws} 
        sendUrl={setUrl} 
        sendMessage={sendMessage} 
      />
    )
  };
};

export default withWebSocket;